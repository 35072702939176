import * as React from 'react';
import Layout from "../../../../Components/layouts";
import aiImg from '../../../../Assets/images/ai.png';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { Pagination } from '@material-ui/lab';
import { SkillsAndTransformation } from 'Services/SkillsAndTransformation';
import { CoursesServices } from 'Services/CoursesServices';
import { handleSSOAuth } from 'Services/AuthService';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { BorderLinearProgress, BorderLinearProgressComplete } from 'Pages/UserAdmin/components/DynamicCard';
import { useLocation, useParams } from "react-router-dom";
import { notify } from 'utils';


const skillsAndTransformation = new SkillsAndTransformation();
const courseServices = new CoursesServices();

export const calculatePercentage = (val, total) => {
    let percentage = parseFloat((val / total)) * 100;
    return percentage ? Math.floor(percentage) : 0;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 180,
        background: '#FFFFFF',
        boxShadow: "0px 4px 30px rgba(12, 41, 84, 0.1)",
        borderRadius: 5
    },
    content: {
        flex: '1 0 auto',
        maxWidth: '80%',
    },
    cover: {
        width: 174,
        height: 132,
        paddingTop: 17,
        marginTop: 25,
        marginLeft: 20,
        borderRadius: 5,
        maxWidth: '20%'
    },
    cardTitle: {
        color: "#808080"
    },
    cardDesc: {
        color: "#808080",
    },
}));

const RegisteredCourses = () => {

    const classes = useStyles();
    const [registerCourseList, setRegisterCourseList] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [emptyMessage, setEmptyMessage] = React.useState('');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get("type") || null;

    React.useEffect(() => {
        setRegisterCourseList([]);
        setLoader(true);
        getAllRegisterCourse();
    }, [location]);

    const getAllRegisterCourse = async () => {
        const courseList = await skillsAndTransformation.getRegisterCourse();
        if (courseList.status) {
            setLoader(false);
            const courseArr = [];
            courseList.data?.courses.forEach((cat) => {
                if(cat.category !== 'Work readiness'){
                    cat.data.forEach((item) => {
                        let { course, labs } = item;
                        let obj = {
                            isLab: false,
                            title: course.category,
                            subTitle: course.course_name,
                            desc: course.description,
                            image: aiImg,
                            total: 100,
                            value: course.course_completion,
                            width: 12,
                            page: 'registeredCourse',
                            progress: true,
                            provider: course.provider,
                            ...course
                        };
                        if(type === "inprogress" && parseFloat(course.course_completion) < 100 && parseFloat(course.course_completion) > 0){
                            courseArr.push(obj);
                        }else if(type === "completed" && parseFloat(course.course_completion) >= 100){
                            courseArr.push(obj);
                        }else if(type === null){
                            courseArr.push(obj);
                        }
                        if(labs && labs.length){
                            labs.forEach((course) => {
                                let obj = {
                                    title: course.vendor,
                                    subTitle: course.name,
                                    desc: course.description,
                                    image: aiImg,
                                    total: 100,
                                    value: course.course_completion,
                                    width: 12,
                                    page: 'registeredCourse',
                                    progress: true,
                                    isLab: true,
                                    provider: course.provider,
                                    ...course
                                }
                                if(type === "inprogress" && parseFloat(course.course_completion) < 100 && parseFloat(course.course_completion) > 0){
                                    courseArr.push(obj);
                                }else if(type === "completed" && parseFloat(course.course_completion) >= 100){
                                    courseArr.push(obj);
                                }else if(type === null){
                                    courseArr.push(obj);
                                }
                            });
                        }
                    });
                }
            });
            if(type === "inprogress" && !courseArr.length){
                setEmptyMessage("No Courses In-Progress");
            }else if(type === "completed" && !courseArr.length){
                setEmptyMessage("No Courses Completed");
            }else{
                setEmptyMessage('');
            }
            setRegisterCourseList(courseArr);
        }
    }

    const handleLinkURL = async (itm) => {
        const { link, token, course_id_talent_lms, course_id, uuid,category,provider } = itm;
        if(itm.isLab && uuid){
            const results = await courseServices.getLabLaunchURL({ ResourceId: uuid });
            if(results?.data?.Token){
                window.open(results?.data?.Token);
            }else{
                notify("error", "Lab Launch URL is not found!");
            }
            return results;
        }
        if (course_id_talent_lms) {
            try {
                courseServices.getSessionLinkToCourse(course_id)
                    .then(({ goto_url }) => {
                        if(goto_url){
                            window.open(goto_url);
                        }else{
                            notify("error", "Launch URL is not found for this course!");
                            return null;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } catch (error) {
                // Send push notification
                console.error(error);
            }
        } else if(provider==='Revinova') {
            handleSSOAuth(link, 'Deviare',category);
        }
        else{
            handleSSOAuth(link, 'simplilearn',category);
        }
    };

    return (
        <Layout>
            <main>
                <Grid container spacing={2}>
                    {loader && <div style={{ marginTop: 30, textAlign: "center", width: "100%" }}>
                        <CircularProgress />
                    </div>}
                    {registerCourseList.length ? registerCourseList.map((item) => (
                        <>
                            <Grid key={item.title} item xs={item.width}>
                                <Card className={classes.root} sx={{ display: "flex" }}>
                                    <CardMedia
                                        className={classes.cover}
                                        image={item.image}
                                        title={item.title}
                                    />
                                    <CardContent className={classes.content} style={{ display: 'flex' }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={9} style={{height: 180, overflow: 'auto'}}>
                                                <Typography className={classes.cardTitle}>
                                                    {item.isLab ? <b>Lab</b> : item.title}
                                                </Typography>
                                                <Typography variant="h1" color="primary">
                                                    {item.subTitle}
                                                </Typography>
                                                <Typography className={classes.cardDesc} title={item.desc}>
                                                    {item.desc}
                                                </Typography>
                                                <Typography variant="h1" color="primary">
                                                    {item.totalCount}
                                                </Typography>

                                                {item.progress ? calculatePercentage(item.value, item.total) !== 100 ? <BorderLinearProgress variant="determinate" value={calculatePercentage(item.value, item.total)} /> :
                                                    <BorderLinearProgressComplete variant="determinate" value={calculatePercentage(item.value, item.total)} /> : null}

                                                <Typography style={{ fontSize: 13, color: "#808080" }} color="primary">
                                                    {calculatePercentage(item.value, item.total) + "% " + "completed"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{ margin: 'auto' }}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => handleLinkURL(item)}
                                                    style={{ width: '100%', padding: '15px 45px', borderRadius: 10 }}>
                                                    {parseFloat(item.value) > 0 ? (
                                                        "Continue learning"
                                                    ) : (
                                                        "LAUNCH"
                                                    )}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </>
                    )) : (!loader) ? <Grid item xs={12} style={{textAlign: "center"}}>
                        <Typography variant='h4' >{emptyMessage || 'No Course Registered!'}</Typography>
                    </Grid> : null}
                </Grid>
                {/* <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Pagination color="secondary" size="large" style={{ padding: "20px  0" }} count={5} page={page} onChange={handlePageChange} />
                </div> */}
            </main>
        </Layout>
    );
}

export default RegisteredCourses;