import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import Keycloak from "keycloak-js";
 
const KeycloakContext = createContext();
 
export const KeycloakProvider = ({ children }) => {
  const keycloak = useMemo(() => {
    const keycloakConfig = {
      url: "https://identity-staging.deviare.co.za/auth",
      realm: "Deviare",
      clientId: "reactjsclient",
    };
    return new Keycloak(keycloakConfig);
  }, []);
 
  const [authenticated, setAuthenticated] = useState(false);
 
  useEffect(() => {
    keycloak.init({ onLoad: "login-required", checkLoginIframe: false })
      .then((auth) => {
        setAuthenticated(auth);
        if (!auth) {
          keycloak.login(); // Redirect to login if not authenticated
        }
      })
      .catch((err) => {
        console.error("Keycloak initialization failed:", err);
      });
  }, [keycloak]);
 
  const login = () => keycloak.login();
  const logout = () => keycloak.logout();
 
  const contextValue = {
    keycloak,
    authenticated,
    login,
    logout,
  };
 
  return (
    <KeycloakContext.Provider value={contextValue}>
      {authenticated ? children : <div>Loading...</div>}
    </KeycloakContext.Provider>
  );
};
 
export const useKeycloak = () => {
  return useContext(KeycloakContext);
};
 