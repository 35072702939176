import "../Sys/config.js";

export const authService = {
    login,
    forgotPassword,
    resetpassword,
    gettheme,
    adminlogin,
    ssologin,
    callback
  };
  
  const apiUrl = global.platformURI;
  //'https://calendar-api.deviare.co.za/';
  
  const cookieUrl = process.env.REACT_APP_KEYCLOAK_COOKIE_ENDPOINT;
  const relayUrl = process.env.REACT_APP_KEYCLOAK_RELAY;
  function login(username, password) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    };
  
    return fetch(`${apiUrl}main/authorize`, requestOptions)
      .then(handleResponse)
      .then(user => {
        return user;
      });
  }
  function adminlogin(username, password) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    };
  
    return fetch(`${apiUrl}main/authorize`, requestOptions)
      .then(handleResponse)
      .then(user => {
        return user;
      });
  }
  function gettheme(branch_url) {
    return fetch(`${apiUrl}main/customertheme?branch_url=` + branch_url)
      .then(handleResponse)
      .then(user => {
        return user;
      });
  }
  function callback(code, state, courseid) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        code: code,
        state: state,
        courseid: courseid
      })
    };
    return fetch(`${apiUrl}main/authprovider`, requestOptions)
      .then(handleResponse)
      .then(user => {
        return user;
      });
  }
  function ssologin(getprams) {
    let url = `${apiUrl}main/authprovider`;
    if (getprams) {
      url = url + '?' + getprams;
    }
  
    return fetch(url)
      .then(handleResponse)
      .then(user => {
        return user;
      });
  }
  
  function forgotPassword(username) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: username })
    };
    return fetch(`${apiUrl}main/forgetpassword`, requestOptions)
      .then(handleResponse)
      .then(user => {
        return user;
      })
      .catch(err => (err.response && err.response.data) ? err.response.data : err);
  }
  
  function resetpassword(userName, token, newPassword) {
    console.log('restting password');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        username: userName,
        token: token,
        password: newPassword
      })
    };
    console.log('requestOptions', requestOptions);
    return fetch(`${apiUrl}main/resetpassword`, requestOptions)
      .then(handleResponse)
      .then(user => {
        return user;
      });
  }
  
  function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          // logout();
        }
  
        const error = data || response.statusText;
        return Promise.reject(error);
      }
  
      return data;
    });
  }
  
  export const handleSSOAuth = (courseUrl, client,category) => {
    let token = localStorage.getItem('IDP:SSOToken');
    let ssoToken = { url_search_string: localStorage.getItem('SSO') };
    try {
      ssoToken = JSON.parse(atob(token));
    } catch (error) {
      ssoToken = { url_search_string: localStorage.getItem('SSO') };
    }
    if (!ssoToken || !courseUrl) {
      console.log('Data Not Found');
      return;
    }
    console.log(courseUrl, ":::::::",client,":::::::",category)
    try {
      console.log(category)
    if(category==='Deviare Courses'){
      window.open(courseUrl);
    }
    if(category='Deviare'){
      window.open(`${courseUrl}`);
    }
      else{
        console.log("Course Not Found");
        let relay = `${relayUrl.replace('{realm_client}', client)}${courseUrl}`;
      let redirectUrl = `${cookieUrl}${ssoToken.url_search_string}${encodeURI(
        relay
      )}`;
      console.log(redirectUrl);
      window.open(redirectUrl);
      }
    } catch (error) {
      alert(error);
    }
  };
  window.handleSSOAuth = handleSSOAuth;
  window.cookieUrl = cookieUrl;
  window.relayUrl = relayUrl;
  